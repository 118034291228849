import { render, staticRenderFns } from "./WidgetCategories.vue?vue&type=template&id=36d1bc00"
import script from "./WidgetCategories.vue?vue&type=script&lang=js"
export * from "./WidgetCategories.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../CitizenPortalUI_Admin/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports